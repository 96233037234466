import { BrandedSiteProps, PageDates } from 'lib/routing';
import { ReactElement } from 'react';
import SchemaOrg, { orgId } from './SchemaOrg';

function stripQuotes(string: string): string {
  return string.replace(/"/g, '');
}

interface OrgDataProps {
  siteData: BrandedSiteProps;
  asPath: string;
  meta: { title: string; description: string };
  pageDates?: PageDates;
}

export default function OrganizationData({
  siteData,
  asPath,
  meta,
  pageDates,
}: OrgDataProps): ReactElement {
  const url = `https://${siteData.domain}`;
  const fullUrl = url + asPath;
  const root = asPath.split('/').filter((item) => item !== '');

  const buildBreadcrumbs = (): string => {
    const items = [
      `{
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "${url}"
      }`,
    ];

    if (root.length > 1 && root[0] === 'locations') {
      items.push(
        `{
          "@type": "ListItem",
          "position": 2,
          "name": "Locations",
          "item": "${url + '/locations'}"
        },
        {
          "@type": "ListItem",
          "position": 3,
          "name": "${stripQuotes(meta.title)}"
        }`
      );
    } else if (
      root.length > 1 &&
      root[0] !== 'doctors' &&
      root[0] !== 'shop' &&
      root[0] !== 'compare' &&
      root[0] !== 'locations'
    ) {
      root.reduce(
        (acc, part, index) => {
          // build out breadcrumb path
          acc.itemPath = acc.itemPath.concat(`/${part}`);
          const itemString = index === root.length - 1 ? '' : `"item": "${url + acc.itemPath}"`;
          const crumb = `{
              "@type": "ListItem",
              "position": ${index + 2},
              "name": "${part.replace(/-/g, ' ')}"${itemString ? ',' : ''}
              ${itemString}
            }`;

          acc.items.push(crumb);

          return acc;
        },
        { items, itemPath: '' }
      );
    } else {
      items.push(`{
        "@type": "ListItem",
        "position": 2,
        "name": "${stripQuotes(meta.title)}"
      }`);
    }

    return items.join(',');
  };

  const org = {
    '@type': 'Organization',
    '@id': orgId(siteData.domain),
    name: stripQuotes(siteData.siteName),
    url: url,
    logo: {
      '@type': 'ImageObject',
      '@id': url + '/#logo',
      inLanguage: 'en-US',
      url: 'https:' + siteData.logo.fields.file.url,
      contentUrl: 'https:' + siteData.logo.fields.file.url,
      width: siteData.logo.fields.file.details.image?.width || 0,
      height: siteData.logo.fields.file.details.image?.height || 0,
      caption: stripQuotes(siteData.siteName),
    },
    image: { '@id': url + '/#logo' },
  };

  const website = {
    '@type': 'WebSite',
    '@id': url + '/#website',
    url: url,
    name: stripQuotes(siteData.siteName),
    description: stripQuotes(meta.title),
    publisher: { '@id': url + '/#organization' },
    potentialAction: [
      {
        '@type': 'SearchAction',
        target: {
          '@type': 'EntryPoint',
          urlTemplate: url + '?q={search_term_string}',
        },
        'query-input': 'required name=search_term_string',
      },
    ],
    inLanguage: 'en-US',
  };

  const webpage = {
    '@type': 'WebPage',
    '@id': fullUrl + '/#webpage',
    url: fullUrl,
    name: stripQuotes(meta.title),
    isPartOf: { '@id': url + '/#website' },
    ...(pageDates
      ? { datePublished: pageDates.datePublished, dateModified: pageDates.dateModified }
      : {}),
    description: stripQuotes(meta.description),
    breadcrumb: {
      '@id': fullUrl + '/#breadcrumb',
    },
    inLanguage: 'en-US',
    potentialAction: [
      {
        '@type': 'ReadAction',
        target: [fullUrl],
      },
    ],
  };

  const breadcrumb = {
    '@type': 'BreadcrumbList',
    '@id': fullUrl + '/#breadcrumb',
    itemListElement: [buildBreadcrumbs()],
  };

  return (
    <SchemaOrg
      json={{
        '@context': 'https://schema.org',
        '@graph': [org, website, webpage, breadcrumb],
      }}
    />
  );
}
