import { ReactElement, useRef, createElement, Fragment } from 'react';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import type { TopLevelBlock, Document, Mark, Text } from '@contentful/rich-text-types';
import useResizeObserver from '../../../lib/resize';

import styles from './styles.module.css';

const TAGS: { [index: string]: string } = {
  paragraph: 'p',
  'heading-1': 'h1',
  'heading-2': 'h2',
  'heading-3': 'h3',
  'heading-4': 'h4',
  'heading-5': 'h5',
  'heading-6': 'h6',
};

const style = {
  width: 'auto',
  height: '40%',
  bottom: '0',
  left: '-5px',
  right: '-5px',
};

const hasUnderline = (value: Mark): boolean => value.type === 'underline';

export function createMarkup(content: Document): { __html: string } {
  return {
    __html: documentToHtmlString(content, {
      renderNode: {
        paragraph: (node, next) => {
          const isEmpty = (node.content[0] as Text).value === '';
          return `<p ${isEmpty ? 'class="mt-4 lg:mt-6"' : ''}>${next(node.content)}</p>`;
        },
      },
    }),
  };
}

export default function RichText({
  document,
  classNames,
  highlight,
}: {
  document: Document;
  classNames?: string;
  highlight?: string;
}): ReactElement {
  const nodeType = document?.content.length ? document.content[0].nodeType : 'heading-1';
  const tag = nodeType === 'paragraph' ? 'h1' : TAGS[nodeType];
  const markedRefs = useRef<HTMLSpanElement[]>([]);

  const { ref } = useResizeObserver<HTMLDivElement>({
    onResize: () => {
      if (markedRefs.current) {
        markedRefs.current.forEach((node, i, all) => {
          if (node) {
            const offset = node.offsetTop;
            const highlight = node.childNodes[1] as HTMLSpanElement;
            const left = all[i - 1];
            const leftOffset = left?.offsetTop;
            const right = all[i + 1];
            const rightOffset = right?.offsetTop;

            if (highlight) {
              if (left && leftOffset === offset) {
                highlight.style.borderTopLeftRadius = '0';
                highlight.style.borderBottomLeftRadius = '0';
              } else {
                highlight.style.borderTopLeftRadius = '9999px';
                highlight.style.borderBottomLeftRadius = '9999px';
              }

              if (right && rightOffset === offset) {
                highlight.style.borderTopRightRadius = '0';
                highlight.style.borderBottomRightRadius = '0';
              } else {
                highlight.style.borderTopRightRadius = '9999px';
                highlight.style.borderBottomRightRadius = '9999px';
              }
            }
          }
        });
      }
    },
  });

  const transform = (node: TopLevelBlock): (string | JSX.Element | undefined)[] => {
    return node.content.map((block) => {
      if (block.nodeType === 'text') {
        const highlighted = block.marks.some(hasUnderline);

        if (highlighted) {
          const parts = block.value.split(' ');

          return (
            <mark className="bg-transparent">
              {parts.map((part, i, arr) => (
                <span
                  key={i + part}
                  ref={(el) => markedRefs.current.push(el as HTMLSpanElement)}
                  className="relative inline-block whitespace-pre font-heading z-10"
                >
                  {part + (i === arr.length - 1 ? '' : ' ')}
                  <span
                    className={`absolute rounded-full -z-1 bg-${highlight}`}
                    style={{ ...style }}
                  ></span>
                </span>
              ))}
            </mark>
          );
        }

        return block.value;
      }
    });
  };

  if (highlight) {
    const content = document.content
      .flatMap((node) => transform(node))
      .map((node, i) => <Fragment key={i}>{node}</Fragment>);

    return createElement(tag, { ref, className: classNames }, content);
  } else {
    return (
      <div
        className={`${classNames || ''} ${styles.richtext_anchor} ${
          styles.richtext_bullet_points
        } ${styles.richtext_ordered_lists} ${styles.richtext_blockquote} ${styles.richtext_ruler} ${
          styles.richtext_header
        }`}
        dangerouslySetInnerHTML={createMarkup(document)}
      ></div>
    );
  }
}
