import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { ReactElement, useState, Fragment, useEffect } from 'react';
import { ISubscriptionLightboxFields } from 'types/contentful';
import styles from './styles.module.css';
import Image from '../Image';

interface ISubscriptionLightboxCookies {
  isClosed: boolean;
  closedTime: number;
  email: string;
  cell: string;
  unsuccessfulAttempts: number;
}

interface SubscriptionLightboxProps {
  siteName: string;
  lightboxData: ISubscriptionLightboxFields;
}

const fetchSubscriptionLightboxData = (): ISubscriptionLightboxCookies => {
  // let prevTime = new Date().setDate(new Date().getDate() - 5);
  const tempData = localStorage.getItem('subscriptionLightboxData') || '{}';
  const tempUnsuccessfulAttempts = JSON.parse(tempData)['unsuccessfulAttempts'] || 0;
  const currentDate = new Date();
  const tempClosed = JSON.parse(tempData)['isClosed'] || false;
  const tempTime = tempClosed
    ? JSON.parse(tempData)['closedTime'] || currentDate.getTime()
    : currentDate.getTime();
  const returnData: ISubscriptionLightboxCookies = {
    isClosed: tempClosed,
    closedTime: tempTime,
    email: JSON.parse(tempData)['email'] || '',
    cell: JSON.parse(tempData)['cell'] || '',
    unsuccessfulAttempts: tempUnsuccessfulAttempts,
  };
  return returnData;
};

const updateSubscriptionCookies = (
  _prevData: ISubscriptionLightboxCookies,
  _isClosed?: boolean,
  _closedTime?: number,
  _email?: string,
  _cell?: string
): void => {
  const tempSubscriptionLightboxData = JSON.parse(JSON.stringify(_prevData));

  tempSubscriptionLightboxData.isClosed = _isClosed; //Update the value of isClosed as passed from arg

  if (_closedTime == -1) {
    delete tempSubscriptionLightboxData.closedTime; //Delete the key for close time from localstorage
  } else {
    tempSubscriptionLightboxData.closedTime = _closedTime; //Update the close time as passed in arg
  }
  if (_email) {
    tempSubscriptionLightboxData.email = _email; //Update email as passed in arg
  }
  if (_cell) {
    tempSubscriptionLightboxData.cell = _cell; //Update cell as passed in arg
  }
  localStorage.setItem('subscriptionLightboxData', JSON.stringify(tempSubscriptionLightboxData));
};

const validateEmail = (_email: string): boolean => {
  let valid = false;
  const validate = String(_email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  if (validate) {
    valid = true;
  }
  return valid;
};

const validateCell = (_cell: string): boolean => {
  let valid = false;
  const validate = String(_cell)
    .toLowerCase()
    .match(/^[-+]?[0-9]+$/);
  if (validate) {
    valid = true;
  }
  return valid;
};

export default function SubscriptionLightbox({
  siteName,
  lightboxData,
}: SubscriptionLightboxProps): ReactElement {
  // console.log("SubscriptionLightboxProps:", lightboxData);
  const timeDelayinAppearingPopup = lightboxData.timeDelay * 1000; //Delay to appear popup In seconds
  const [isClosed, setIsClosed] = useState(false);
  const [popupDelayOver, setPopupDelayOver] = useState(false);
  const [subscriptionLightboxData, setSubscriptionLightboxData] = useState({
    isClosed: false,
    closedTime: new Date().getTime(),
    email: '',
    cell: '',
    unsuccessfulAttempts: 0,
  });
  const [userEmail, setUserEmail] = useState('');
  const [userCell, setUserCell] = useState('');
  const [validationError, setValidationError] = useState('');
  //Delay in appearing the Subscription popup
  setTimeout(() => {
    setPopupDelayOver(true);
  }, timeDelayinAppearingPopup);

  const resetCookiesAfterRecurringTime = (): void => {
    const recurringDays = lightboxData.frequency || 30; //Refers to number of days the popup should recur.
    const closedDate = new Date(subscriptionLightboxData.closedTime);
    const unsuccessfulAttempts = subscriptionLightboxData.unsuccessfulAttempts || 0;
    // let nextPopupTime = new Date().setDate(closedDate.getDate() + recurringDays);
    const nextPopupTime =
      closedDate.getTime() +
      (unsuccessfulAttempts >= 3 ? 3 : 1) * recurringDays * 1000 * 60 * 60 * 24;

    const nextPopupDate = new Date(nextPopupTime);
    const todaysDate = new Date();
    // console.log("\n\n============\nsubscriptionLightboxData:", subscriptionLightboxData);
    // console.log("timeDelayinAppearingPopup:", timeDelayinAppearingPopup);
    // console.log("recurringDays:", recurringDays);
    //console.log('closedDate: ', closedDate);
    //console.log('nextPopupDate: ', nextPopupDate);
    //console.log('todaysDate: ', todaysDate);

    if (nextPopupDate <= todaysDate) {
      //Reset the cookies state and clear all previous subscription data when the reccurence time triggers
      updateSubscriptionCookies(subscriptionLightboxData, false, -1, '', '');
      setSubscriptionLightboxData(fetchSubscriptionLightboxData());
    }
  };

  // fetch data
  useEffect(() => {
    setSubscriptionLightboxData(fetchSubscriptionLightboxData());
  }, []);

  //
  useEffect(() => {
    //console.log('subscriptionLightboxData:', subscriptionLightboxData);
    console.log('closed :', subscriptionLightboxData.isClosed);
    resetCookiesAfterRecurringTime();
    setUserEmail(subscriptionLightboxData.email);
    setUserCell(subscriptionLightboxData.cell);
    setIsClosed(subscriptionLightboxData.isClosed);
  }, [subscriptionLightboxData]);

  let headingText = lightboxData.heading;
  headingText = headingText.replaceAll('<highlight>', `<span style="color: #FFDD00">`);
  headingText = headingText.replaceAll('</highlight>', '</span>');

  let bannerTextDefault = documentToHtmlString(lightboxData.bannerTextDefault) || '';
  bannerTextDefault = bannerTextDefault.replaceAll('{siteName}', siteName);

  let bannerTextMobile = documentToHtmlString(lightboxData.bannerTextMobile) || '';
  bannerTextMobile = bannerTextMobile.replaceAll('{siteName}', siteName);

  //
  const handlerOnClose = (): void => {
    const tempSubscriptionLightboxData = subscriptionLightboxData;
    tempSubscriptionLightboxData.unsuccessfulAttempts =
      (tempSubscriptionLightboxData.unsuccessfulAttempts || 0) + 1;

    updateSubscriptionCookies(tempSubscriptionLightboxData, true, new Date().getTime());
    setIsClosed(true);
  };

  const handlerOnSubmit = (): void => {
    console.log('User submitted subscription data');
    if (validateEmail(userEmail.trim())) {
      setValidationError('');
    } else {
      console.log('* Please enter valid email address:', userEmail);
      setValidationError('* Please enter valid email address.');
      return;
    }

    if (validateCell(userCell.trim())) {
      setValidationError('');
    } else {
      if (userCell.trim() !== '') {
        setValidationError('* Please enter valid mobile number.');
        return;
      }
    }
    const tempSubscriptionLightboxData = subscriptionLightboxData;
    if (userCell.trim() === '') {
      tempSubscriptionLightboxData.unsuccessfulAttempts =
        (tempSubscriptionLightboxData.unsuccessfulAttempts || 0) + 1;
    }
    //
    updateSubscriptionCookies(
      tempSubscriptionLightboxData,
      true,
      new Date().getTime(),
      userEmail,
      userCell
    );

    //Send request to Cordial
    if (userEmail.trim() !== '') {
      const auth_data = {
        email: userEmail,
      };
      const contact_data = {
        channels: {
          email: {
            subscribeStatus: 'subscribed',
            address: userEmail,
          },
        },
        cell: userCell,
        welcome_list: true,
      };

      //
      console.log('Invoke Cordial API here');
      window.crdl &&
        window.crdl('contact', auth_data, contact_data, { forceSubscribe: { email: true } });
    }

    //
    setIsClosed(true);
  };

  // useEffect(() => {
  //    console.log("email:", userEmail, "ph:", usercell);
  // }, [userEmail, usercell])

  return (
    <>
      {!isClosed &&
      !(subscriptionLightboxData.email !== '' && subscriptionLightboxData.cell !== '') &&
      popupDelayOver ? (
        <div className={`${'subscription-lightbox w-full'} ${styles.subscription_lightbox}`}>
          <div
            className={`${'layer w-full flex flex-col justify-center items-center'} ${
              styles.layer
            }`}
            style={{ zIndex: 2 }}
          >
            <div className={`${'content_area flex flex-col'} ${styles.content_area}`}>
              <div
                role="button"
                tabIndex={0}
                className={`${'close_button flex justify-center items-center'} ${
                  styles.close_button
                }`}
                onClick={handlerOnClose}
              >
                +
              </div>

              <div className={`${'heading_container'} ${styles.heading_container}`}>
                <h1 dangerouslySetInnerHTML={{ __html: headingText }}></h1>
              </div>
              <div className={`${'description_area flex flex-col'} ${styles.description_area}`}>
                <div className={`${'field_container'} ${styles.field_container}`}>
                  {subscriptionLightboxData.email === '' && (
                    <input
                      className={`${'inputField'} ${styles.inputField}`}
                      type="email"
                      placeholder="Email address"
                      required
                      onChange={(e) => {
                        setUserEmail(e.target.value);
                      }}
                    />
                  )}
                  {subscriptionLightboxData.cell === '' && (
                    <input
                      className={`${'inputField'} ${styles.inputField}`}
                      type="number"
                      placeholder="Mobile number (optional)"
                      onChange={(e) => {
                        setUserCell(e.target.value);
                      }}
                    />
                  )}
                  {validationError && <div style={{ color: '#cd0909' }}>{validationError}</div>}
                </div>
                <div
                  className={`${'desktopView'} ${styles.desktopView} ${
                    subscriptionLightboxData.email !== '' &&
                    subscriptionLightboxData.cell === '' &&
                    styles.addMarginTop
                  }`}
                  dangerouslySetInnerHTML={{ __html: bannerTextDefault }}
                ></div>
                <div
                  className={`${'mobileView'} ${styles.mobileView}`}
                  dangerouslySetInnerHTML={{
                    __html: bannerTextMobile ? bannerTextMobile : bannerTextDefault,
                  }}
                ></div>
              </div>
              <div
                className={`${'submit_container flex flex-row justify-between items-center'} ${
                  styles.submit_container
                }`}
              >
                <Image image={lightboxData.logo} query="" classNames="sm:max-w-200 max-w-200" />
                <div
                  role="button"
                  tabIndex={0}
                  className={`${'submit_button'} ${styles.submit_button}`}
                  onClick={handlerOnSubmit}
                >
                  SUBMIT
                </div>
              </div>
            </div>
          </div>

          <div className={`${'layer backdrop w-full'} ${styles.layer} ${styles.backdrop}`}></div>
        </div>
      ) : (
        ''
      )}
    </>
  );
}
