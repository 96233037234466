import { ReactElement } from 'react';
import { IFragmentCtaFields } from 'types/contentful';
import Link from './Link';
interface CTAProps extends Omit<IFragmentCtaFields, 'title'> {
  classNames?: string;
  backgroundColor?: string;
  onClick?: () => void;
  disabled?: boolean;
}

interface ColorOptions {
  [key: string]: {
    fill: string;
    text?: string;
    borderColor?: string;
  };
}

const BASE_CLASSES =
  'inline-block text-center w-full md:w-auto md:min-w-cta px-10 py-4 text-sm font-semibold rounded-full';

const OPTS: ColorOptions = {
  Primary: {
    fill: 'highlight',
    text: 'black',
  },
  Primary2: {
    fill: 'secondary',
    text: 'black',
  },
  Primary3: {
    fill: 'tertiary',
    text: 'white',
  },
  Outlined: {
    fill: 'transparent',
  },
  OutlinedTeal: {
    fill: 'highlight',
    text: 'teal-fontcolor',
    borderColor: 'border-teal',
  },
  OutlinedDarkteal: {
    fill: 'secondary',
    text: 'black',
    borderColor: 'border-darkteal',
  },
  OutlinedYellow: {
    fill: 'tertiary',
    text: 'black',
    borderColor: 'border-yellow',
  },
  White: {
    fill: 'white',
    text: 'black',
  },
};

const classesHelper = (colorScheme: string, backgroundColor?: string): string => {
  const scheme = OPTS[colorScheme] || OPTS.Primary;

  if (colorScheme === 'Outlined') {
    // white is a special case because it is a color and not a brand concept like muted or primary
    // we therefore need to change the text to black when the background is white and not rely on
    // a text color established by the brand
    const textColor = backgroundColor === 'white' ? 'black' : backgroundColor;
    return `border border-current bg-${scheme.fill} text-${textColor}`;
  } else if (
    colorScheme === 'OutlinedTeal' ||
    colorScheme === 'OutlinedDarkteal' ||
    colorScheme === 'OutlinedYellow'
  ) {
    return `border ${scheme.borderColor} text-${scheme.text}`;
  } else {
    return `bg-${scheme.fill} text-${scheme.text}`;
  }
};

const checkIsExternal = (slug: string): boolean =>
  slug.startsWith('http') || slug.startsWith('tel:') || slug.startsWith('mailto:');

export default function CTA({
  text,
  slug,
  colorScheme,
  backgroundColor,
  classNames = '',
  linkToAsset,
  openInNewTab,
  onClick,
  disabled = false,
}: CTAProps): ReactElement {
  const classes = classesHelper(colorScheme, backgroundColor);
  const isExternal = checkIsExternal(slug) || linkToAsset !== undefined;
  let target = '';

  if (openInNewTab === true || linkToAsset !== undefined) {
    target = '_blank';
  }

  if (disabled)
    return (
      <button
        disabled
        className={`${BASE_CLASSES} ${classes} ${classNames} text-opacity-25 cursor-auto`}
      >
        {text}
      </button>
    );

  if (onClick)
    return (
      <button onClick={onClick} className={`${BASE_CLASSES} ${classes} ${classNames}`}>
        {text}
      </button>
    );

  const Internal = (): ReactElement => {
    const [querylessSlug, query] = slug.split('?');
    return (
      <Link slug={querylessSlug} query={query}>
        <a target={target} className={`${BASE_CLASSES} ${classes} ${classNames}`}>
          {text}
        </a>
      </Link>
    );
  };

  const External = (): ReactElement => (
    <a
      href={linkToAsset ? `https://${linkToAsset.fields.file.url}` : slug}
      target={target}
      className={`${BASE_CLASSES} ${classes} ${classNames}`}
      rel="noreferrer"
    >
      {text}
    </a>
  );
  return isExternal ? <External /> : <Internal />;
}
